import { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'

export default {
  type: CONTENT_BLOCK_TYPE_ARTICLE,
  title: '',
  hidden: false,
  site: '', // only name as string
  category: '',
  identifier: '',
  settings: '',
  items: [],
  internalFallback: false,
  fallbackSite: null,
  fallbackRubrics: [],
  fallbackTags: [],
  fallbackCategories: [],
  fallbackFlag: {
    video: false,
    imageGallery: false
  },
  fallbackSetting: {
    prArticle: false
  },
  fallbackArticleType: {
    article: false,
    external: false,
    video: false,
    quiz: false,
    photostory: false
  },
  externalFallback: false,
  numberOfItems: 0,
  position: 0,
  displayPosition: 0,
  daysLimit: 365,
  disabledPositions: '0',
  fallbackPositions: '0',
  expanded: {
    fallbackRubrics: [],
    fallbackTags: [],
    fallbackCategories: []
  }
}
