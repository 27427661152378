const buildFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.site !== '0' && filter.site > 0) {
    filterQuery += '&siteId=' + filter.site
  }
  if (filter.id) {
    filterQuery += '&id=' + filter.id
  }
  if (filter.type) {
    filterQuery += '&type=' + filter.type
  }
  if (filter.title) {
    filterQuery += '&title=' + filter.title
  }
  if (filter.identifier) {
    filterQuery += '&identifier=' + filter.identifier
  }
  return filterQuery
}

const buildHpManagementFilterQuery = function (filter) {
  var filterQuery = ''
  if (filter.contentBlocks.length > 0) {
    filterQuery += '&id=' + filter.contentBlocks
  }
  if (filter.site) {
    filterQuery += '&siteId=' + filter.site
  }
  return filterQuery
}

export default {
  buildFilterQuery,
  buildHpManagementFilterQuery
}
