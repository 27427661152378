import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS

const routes = [
  {
    path: 'tag/:id/edit',
    component: () => import('../../views/tag/TagEditView'),
    name: 'tag_edit',
    meta: {
      description: 'Tag edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'tag/new',
    component: () => import('../../views/tag/TagNewView'),
    name: 'tag_new',
    meta: {
      description: 'Tag new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'tag',
    component: () => import('../../views/tag/TagListView'),
    name: 'tag_list',
    meta: {
      description: 'Tag list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
