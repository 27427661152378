import CoreApi from '@/api/core'

const API_ACTIVITY_ARTICLE = '/ArticleLock'
const API_ACTIVITY = '/ArticleLock'

/**
 * This makes sure that only one Activity request is being run at a time.
 *
 * This prevents overloading of the system with Activity requests.
 */
const runSingleRequest = async ({ state, commit }, actionFunction) => {
  if (state.runningRequest) {
    return
  }

  try {
    commit('SET_RUNNING_REQUEST', true)
    return await actionFunction()
    // DO NOT put catch() in here as error handling is done in parent components
  } finally {
    commit('SET_RUNNING_REQUEST', false)
  }
}

const state = {
  articleActivities: [],
  articleActivity: [],
  trackingInterval: process.env.NODE_ENV === 'development' ? 10000 : 1000,
  runningRequest: false,
  autoSaveDraftTime: 900000
}

const mutations = {
  SET_ARTICLE_ACTIVITIES (state, response) {
    state.articleActivities = response.data
  },
  SET_ARTICLE_ACTIVITY (state, response) {
    state.articleActivities = response.data
  },
  SET_RUNNING_REQUEST (state, running) {
    state.runningRequest = running
  }
}

const actions = {
  async putArticleActivity (store, { articleDocumentId, articleId }) {
    return await runSingleRequest(store, async () => {
      if (!articleDocumentId || !articleId) {
        return 'articleDocumentId or articleId are required'
      }
      return await CoreApi()
        .put(`${API_ACTIVITY_ARTICLE}/${articleDocumentId}?articleId=${articleId}`)
    })
  },

  async getArticleActivities (store) {
    return await runSingleRequest(store, async () => {
      return await CoreApi()
        .get(API_ACTIVITY)
        .then(response => {
          store.commit('SET_ARTICLE_ACTIVITIES', response)
        })
        .catch(error => {
          console.error(error)
        })
    })
  }
}

const getters = {
  articleActivities (state) {
    return state.articleActivities
  },

  articleActivityById (state) {
    return id => state.articleActivities.find(activity => {
      if (id === activity.articleUuid) {
        return activity
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
