export default {
  campaignId: null,
  agency: '',
  client: '',
  clicksLimit: '',
  clickPrice: '',
  publishedFrom: '',
  publishedTo: '',
  salesman: '',
  status: null,
  note: '',
  orderId: '',
  user: {
    id: '',
    name: ''
  },
  category: '',
  mainAlternative: {
    name: 'A',
    url: '',
    title: '',
    damImageId: '',
    damImageFileName: ''
  },
  alternatives: [],
  brands: [],
  segments: [],
  rrk: false,
  duplicate: false,
  paused: false,
  isFree: false
}
