// for capabilities check https://newsandmedia.atlassian.net/wiki/spaces/FLC/pages/3262513184/Zoznam+modulov+a+ich+capabilit+podla+CMS

export const MODULE_REALTIME_STATS = 'realtime_stats'
export const MODULE_ARTICLE = 'article'
export const MODULE_CONTENT_BLOCK_ITEM = 'content_block_item'
export const MODULE_HOMEPAGE_TOPIC_OFFER = 'hp_topic_offer'
/**
 * [eagle-dam/image, eagle-dam/pdf]
 */
export const MODULE_DAM = 'digital_asset_management'
export const MODULE_VIDEO = 'video'
export const MODULE_VIDEO_MANAGEMENT = 'video_management'
export const MODULE_VIDEO_STATISTICS = 'video_stats'
export const MODULE_VIDEO_DAILY_REPORTS = 'video_daily_reports'
export const MODULE_VIDEO_PUBLISHED_REPORTS = 'video_published_reports'
export const MODULE_VIDEO_KPI_REPORTS = 'video_kpi_reports'
export const MODULE_VIDEO_SHOW = 'video_show'
export const MODULE_VIDEO_CATEGORY = 'video_category'
/**
 * MODULES: such as infobox, joke, poll, quote, etc.
 */
export const MODULE_MODULES_INFOBOX = 'infobox'
export const MODULE_MODULES_JOKE = 'joke'
export const MODULE_MODULES_QUOTE = 'quote'
export const MODULE_MODULES_POLL = 'poll'
export const MODULE_MODULES_POLL_BLOCK = 'pollblock'
export const MODULE_MODULES_QUIZ = 'quiz'
export const MODULE_MODULES_TOPIC = 'topic'
export const MODULE_MODULES_RECIPE = 'recipe'
export const MODULE_MODULES_GALLERY = 'gallery'
export const MODULE_MODULES_SPORT24 = 'sport24'
export const MODULE_ARTICLE_TEST = 'test'
export const MODULE_ARTICLE_OFFER_TASR = 'tasr'
export const MODULE_ARTICLE_OFFER_WOOD_WING = 'woodwing'
export const MODULE_PRINT_PUBLICATION = 'print_publication'
export const MODULE_PRINT_ARTICLE = 'print_article'
export const MODULE_PRINT_TITLE = 'print_title'
export const MODULE_ARTICLE_PERFORMANCE = 'article_performance'
export const MODULE_EDITOR_PERFORMANCE = 'editor_performance'
export const MODULE_NATIVE_CAMPAIGN_MANAGEMENT = 'native_campaign_management'
export const MODULE_ESHOP = 'eshop'

/**
 * such as sites, rubrics, categories, etc.
 */
export const MODULE_SYSTEM = 'system'
export const MODULE_SYSTEM_USER_MANAGEMENT = 'user_management'
export const MODULE_SYSTEM_CONTENT_BLOCK = 'content_block'
export const MODULE_SYSTEM_NOTIFICATION_SERVICE = 'notification_service'
