const messages = {
  blocks: {
    carTableBlock: {
      title: 'Tabulka vozidla',
      empty: 'Vložit tabulku vozidla',
      notEmpty: 'Je vložena tabulka s ID:'
    },
    codeBlock: {
      title: 'Embed zdrojového kódu',
      placeholder: 'Napište nebo vložte kód...'
    },
    thirdPartyEmbedBlock: {
      title: 'Social media embed',
      empty: 'Vložit social media embed',
      notEmpty: 'Social media embed'
    },
    headingBlock: {
      title: 'Nadpisy',
      placeholder: 'Váš mezititulek...'
    },
    imageBlock: {
      deletePhoto: 'Změnit obrázek',
      selectPhoto: 'Vybrat obrázek',
      title: 'Obrázek',
      uploadPhoto: 'Nahrát obrázek',
      uploadPhotoFromUrl: 'URL fotky',
      author: 'Autor'
    },
    infoBoxBlock: {
      title: 'Infobox',
      empty: 'Vložit infobox',
      notEmpty: 'Je vložen infobox'
    },
    infographicBlock: {
      title: 'Infografika',
      empty: 'Vložit infografiku'
    },
    linkToGalleryBlock: {
      title: 'Odkaz na galerii',
      page: 'Číslo strany – pořadové číslo obrázku v galerii obrázků v tomto článku',
      text: 'Text odkazu na danou stranu – text odkazu na zadané číslo strany',
      relatedArticleGalleryButton: 'Vložit související galerii'
    },
    onlineSportMatchBlock: {
      title: 'Online zápas',
      matchId: 'ID zápasu',
      matchType: 'Kategorie',
      categoryOther: 'Ostatní',
      categoryHockey: 'Hokej',
      categoryFootball: 'Fotbal',
      categoryTennis: 'Tenis'
    },
    orderedListBlock: {
      title: 'Číslovaný seznam'
    },
    pageBreakBlock: {
      title: 'Zalomení strany',
      notEmpty: 'Je vložen zlem strany',
      desc: 'Zlom strany (pokračování na další straně)'
    },
    paragraphBlock: {
      title: 'Text',
      placeholder: 'Váš text...'
    },
    placeholderBlock: {
      title: 'Placeholder',
      start: 'Začněte v psaní, nebo přidejte obsahový blok...',
      end: 'Pokračujte v psaní, nebo přidejte obsahový blok...'
    },
    paidContentBreakBlock: {
      title: 'Placený obsah - zlom',
      notEmpty: 'Je vložen zlem placeného obsahu',
      desc: 'Zlom - placený obsah'
    },
    pollBlock: {
      title: 'Anketa',
      empty: 'Vložit anketu',
      notEmpty: 'Je vložena anketa'
    },
    quizBlock: {
      title: 'Kvíz',
      empty: 'Vložit kvíz',
      notEmpty: 'Je vložený kvíz'
    },
    quoteBlock: {
      title: 'Citát',
      author: 'Jméno autora...',
      text: 'Váš text...'
    },
    sportTableBlock: {
      title: 'Sportovní tabulka',
      seasonId: 'Id tabulky',
      activeTab: 'Aktivní tab (1 = první)'
    },
    relatedArticleBlock: {
      title: 'Související článek',
      empty: 'Vložit související článek'
    },
    unorderedListBlock: {
      title: 'Odrážky'
    },
    sourceBlock: {
      title: 'Zdroje'
    },
    videoBlock: {
      title: 'Video',
      empty: 'Vložit video'
    },
    youtubeBlock: {
      title: 'Youtube video',
      empty: 'Vložit youtube video'
    },
    tableBlock: {
      title: 'Tabulka',
      empty: 'Vložit tabulku'
    }
  },
  loading: 'Načítám...',
  btns: {
    btnAddBlock: 'Přidat nový obsahový blok',
    close: 'Zavřít',
    delete: 'Vymazat'
  },
  modals: {
    btn: {
      confirm: 'Potvrdit',
      cancel: 'Zrušit'
    },
    copyPaste: {
      title: 'Vložte Váš obsah ze schránky',
      btnSend: 'Potvrdit'
    },
    inlineQuote: {
      title: 'Vložit citovaný zdroj',
      citeLabel: 'Zdroj'
    },
    deleteBlock: {
      title: {
        all: 'Opravdu chcete vymazat <strong>všechny</strong> bloky?',
        single: 'Opravdu chcete vymazat tento blok?'
      }
    },
    selectBlock: {
      title: 'Vyberte obsahový blok do článku'
    },
    link: {
      title: 'Vložit url adresu',
      urlLabel: 'Url',
      titleLabel: 'Text',
      bodyBlockLabel: 'Odkaz z článku',
      invalidUrl: 'Neplatná url adresa',
      invalidTitle: 'Text nemůže být prázdný',
      targetLabel: 'Cíl',
      newWindow: 'V novém okně',
      currentWindow: 'Ve stejném okně',
      relLabel: 'rel',
      notFilled: 'nevyplněno',
      nofollow: 'nofollow',
      btn: {
        confirm: 'Potvrdit',
        cancel: 'Zrušit'
      }
    },
    thirdPartyEmbed: {
      titleSocialEmbed: 'Social media embed',
      titleInfographic: 'Infografika',
      storyError: 'Do článku nelze vložit Embed typu Story. Vkládejte pouze Embed typu Post.',
      inputLabel: 'Vložte URL',
      btn: {
        confirm: 'Vložit do článku'
      }
    },
    source: {
      title: 'Zdroje článku',
      name: 'Název zdroje',
      url: 'Odkaz na zdroj',
      addSource: 'Přidat zdroj'
    },
    htmlIdAttr: {
      titleRest: 'Vložit <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id" target="_blank">HTML identifikátor</a> bloku',
      info: 'Poznámka: Technicky může hodnota atributu id obsahovat jakýkoli znak kromě mezer. Aby se však předešlo neúmyslným chybám, měla by být použita pouze písmena ASCII, číslice, \'_\' a \'-\' a hodnota atributu id by měla začínat písmenem. Například, . má v CSS zvláštní význam (funguje jako selektor třídy). Pokud si nedáte pozor, abyste jej v CSS neuzavřeli, nebude rozpoznán jako součást hodnoty atributu id. Je snadné na to zapomenout, což má za následek chyby ve vašem kódu, které může být obtížné odhalit.',
      existingLinkWarning: 'Na toto ID se odkazuje text "{text}" v bloku "{htmlId}". Pokud ID upravíte, odkaz přestane fungovat.',
      duplicateId: 'Toto ID již v článku existuje',
      generateUniqueId: 'Vygenerovat unikátní ID'
    }
  },
  tooltips: {
    addBlock: 'Přidat obsahový blok',
    deleteBlock: 'Vymazat blok',
    deleteBlocks: 'Vymazat všechny bloky',
    editBlock: 'Upravit blok',
    moveBlock: 'Přesunout blok',
    changeBlock: 'Změnit typ bloku',
    copyPaste: 'Vložit ze schránky',
    htmlIdAttr: 'HTML ID atribut',
    undo: 'Zpět',
    redo: 'Znovu',
    bold: 'Tučné',
    code: 'Kód',
    italic: 'Kurzíva',
    link: 'Odkaz',
    unlink: 'Zrušit odkaz',
    inlineQuote: 'Citovaný zdroj',
    strike: 'Přeškrtnuté',
    underline: 'Podtržené',
    insertedTag: 'Aktualizace textu',
    subscript: 'Dolní index',
    superscript: 'Horní index',
    headingTitle: 'Nadpis {level}. úrovně',
    table: {
      insertRowBefore: 'Vložit řádek před',
      insertRowAfter: 'Vložit řádek za',
      deleteRow: 'Vymazat řádek',
      insertColumnBefore: 'Vložit sloupec před',
      insertColumnAfter: 'Vložit sloupec za',
      deleteColumn: 'Vymazat sloupec',
      mergeCells: 'Sloučit buňky',
      splitCells: 'Rozdělit buňky',
      toggleHeaderRow: 'Přepnout řádek s hlavičkou',
      toggleHeaderColumn: 'Přepnout sloupec s hlavičkou'
    },
    align: {
      left: 'Zarovnat vlevo',
      center: 'Zarovnat na střed',
      right: 'Zarovnat vpravo'
    }
  }
}

export default messages
