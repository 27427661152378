import _ from 'lodash'
import CoreApi from '../../api/core'
import TopicModel from '../../model/TopicModel'
import TopicFilterModel from '../../model/TopicFilter'
import TopicFilterService from '../../services/topic/TopicFilterService'
import Config from '../../config'

const API_NAME = '/topic'
const API_NAME_USAGES = '/article'

const state = {
  error: null,
  detail: TopicModel,
  list: [],
  all: [],
  articleTopics: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(TopicFilterModel),
  limit: Config.defaults.list.limit,
  image: null,
  imageBg: null,
  usages: {
    id: null,
    list: [],
    totalCount: 0,
    page: 1,
    limit: Config.defaults.list.limit
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeUsagesList (state, responseData) {
    state.usages.list = responseData.data
    state.usages.totalCount = responseData.totalCount
  },
  storeArticleTopics (state, responseData) {
    state.articleTopics = responseData.data
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeDetail (state, responseData) {
    responseData.expanded.articleTypes = []
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setUsagesPage (state, page) {
    state.usages.page = page
  },
  setUsagesId (state, id) {
    state.usages.id = id
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setUsagesLimit (state, limit) {
    state.usages.limit = limit
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(TopicFilterModel)
  },
  storeImage (state, image) {
    state.image = image
  },
  storeImageBg (state, imageBg) {
    state.imageBg = imageBg
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * store.state.limit) - store.state.limit
    const url = API_NAME +
      '?view=minimal&limit=' + store.state.limit +
      '&offset=' + offset +
      '&order[id]=desc' + TopicFilterService.buildFilterQuery(store.state.filter)
    return await CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchUsages (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.usages.page * store.state.usages.limit) - store.state.usages.limit
    const url = API_NAME_USAGES +
      '?view=minimal&limit=' + store.state.usages.limit +
      '&offset=' + offset +
      '&order[id]=asc' + TopicFilterService.buildUsagesQuery(store.state.usages.id)
    return await CoreApi().get(url)
      .then(res => {
        store.commit('storeUsagesList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    return await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeImage', null)
        store.commit('storeImageBg', null)
        store.commit('storeDetail', response.data)
        if (response.data.image) {
          store.dispatch('fetchImage', response.data.image)
        }
        if (response.data.imageBg) {
          store.dispatch('fetchImageBg', response.data.imageBg)
        }
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  async fetchBySlugAndSite (store, data) {
    return await CoreApi().get(
      API_NAME + '?view=minimal&limit=' + store.state.limit + '&filter_eq[slug]=' +
      data.slug + '&filter_eq[site]=' + data.site
    )
      .then(res => {
        store.commit('storeList', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchArticleTopic (store, data) {
    return await CoreApi().get(
      API_NAME + '?view=minimal&limit=' + store.state.limit + '&order[articleCount]=desc' +
      TopicFilterService.buildArticleTopicsQuery(data)
    )
      .then(res => {
        store.commit('storeArticleTopics', res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchImage (store, id) {
    return await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeImage', response.data)
      })
      .catch(error => console.log(error))
  },
  async fetchImageBg (store, id) {
    return await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeImageBg', response.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  usagesList (state) {
    return state.usages.list
  },
  articleTopics (state) {
    return state.articleTopics
  },
  all (state) {
    return state.all
  },
  totalCount (state) {
    return state.totalCount
  },
  usagesTotalCount (state) {
    return state.usages.totalCount
  },
  page (state) {
    return state.page
  },
  usagesPage (state) {
    return state.usages.page
  },
  usagesId (state) {
    return state.usages.id
  },
  limit (state) {
    return state.limit
  },
  usagesLimit (state) {
    return state.usages.limit
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  image (state) {
    return state.image
  },
  imageBg (state) {
    return state.imageBg
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
